import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import { isPersistent, isVolatile, MAX_TIME } from "@/__main__/constants.mjs";
import getData, { postData, readData } from "@/__main__/get-data.mjs";
import {
  addFavoriteGame,
  setVolatileKV,
  writeSettings,
} from "@/app/actions.mjs";
import {
  mergeLocalWithApi,
  mergeNestedObjWithApi,
  upsertLocalData,
} from "@/app/util.mjs";
import { ApexCreateMatchModel } from "@/data-models/apex-create-match.mjs";
import { ApexMatchModel } from "@/data-models/apex-match.mjs";
import type { ApexPlayer } from "@/data-models/apex-player.mjs";
import {
  ApexPlayerModel,
  UpdateApexPlayerModel,
} from "@/data-models/apex-player.mjs";
import {
  ApexPlayerChampionSeasonStatsInput,
  ApexPlayerLegendStatsModel,
  UpdateApexPlayerLegendStatsModel,
} from "@/data-models/apex-player-legend-stats.mjs";
import {
  ApexPlayerStatsModel,
  UpdateApexPlayerStatsModel,
} from "@/data-models/apex-player-stats.mjs";
import {
  ApexPlayerWeaponStatsModel,
  UpdateApexPlayerWeaponStatsModel,
} from "@/data-models/apex-player-weapon-stats.mjs";
import noopModel from "@/data-models/no-op.mjs";
import * as API from "@/game-apex/api.mjs";
import GameMode from "@/game-apex/constants/game-modes.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition-symbol.mjs";
import clone from "@/util/clone.mjs";
import deepEqual from "@/util/deep-equal.mjs";

export const initApexSettings = () => {
  if (readState.settings.loggedInAccounts.apex.platformId)
    readData([
      "apex",
      "profiles",
      readState.settings.loggedInAccounts.apex.platformId,
    ]);
};

export function updateSeasons(seasons, rankedSeasons, arenaRankedSeasons) {
  const newSeasons = clone(readState.apex.meta.seasons);
  // Don't add season 1, it has no stats
  for (let i = 0; i < seasons.length - 1; i++) {
    const apexId = seasons[i];
    const apexRankedPeriodId = rankedSeasons[i];
    const apexArenaRankedPeriodId = arenaRankedSeasons[i];
    if (!newSeasons[apexId]) {
      newSeasons[apexId] = {
        apexId,
        apexRankedPeriodId,
        apexArenaRankedPeriodId,
        apexSeasonNumber: seasons.length - i,
        id: String(seasons.length - i),
      };
    }
  }

  setVolatileKV("currentSeasons", {
    season: seasons[0],
    rankedSeason: rankedSeasons[0],
    arenaRankedSeason: arenaRankedSeasons[0],
  });

  if (!newSeasons.ALL)
    newSeasons.ALL = {
      apexId: "ALL",
      id: "1",
    };

  newSeasons[isPersistent] = MAX_TIME;

  writeState.apex.meta.seasons = newSeasons;
}

export function updateLoggedInAccountId(profileId) {
  writeSettings(["lastLoggedInIdByGame", "apex"], profileId);
}

export async function updateProfile(profileId, update: ApexPlayer, isOverlay) {
  if (!profileId) return;
  await readData(["apex", "profiles", profileId]);
  const profile = clone(readState.apex.profiles[profileId]);
  const newProfile = {
    ...(profile || {}),
    ...update,
    updatedAt: new Date(),
  };
  newProfile[isPersistent] = MAX_TIME;
  writeState.apex.profiles[profileId] = newProfile;
  const {
    platformId,
    experiencePoints,
    hoveredChampionApexId,
    hardwareId,
    username,
  } = newProfile;

  if (profileId && hoveredChampionApexId && username) {
    writeSettings(["loggedInAccounts", "apex", profileId], {
      hoveredChampionApexId,
      username,
    });
    addFavoriteGame(GAME_SYMBOL_APEX);
  }

  if (
    !isOverlay &&
    platformId &&
    typeof experiencePoints === "number" &&
    hoveredChampionApexId &&
    hardwareId &&
    username
  ) {
    const platformProfile = UpdateApexPlayerModel(
      {
        experiencePoints,
        hoveredChampionApexId,
        hardwareProfiles: [
          {
            hardwareId,
            username,
          },
        ],
      },
      { isPostValidation: true, removeEmpty: true },
    );

    upsertLocalData({
      curValue: profile,
      newValue: platformProfile,
      updateKey: "platformProfile",
      writeStatePath: ["apex", "profiles", platformId],
      getQuery: API.getPlayer({ platformId }),
      getOptions: { networkBackOffTime: 0 },
      createQuery: API.createPlayer({
        platformProfile: {
          ...platformProfile,
          platformId,
          profile: {
            apexId: platformId,
          },
        },
      }),
      updateQuery: API.updatePlayer,
      shouldUpdate: (curValue, newValue) => {
        const {
          experiencePoints,
          hoveredChampionApexId,
          hardwareId,
          username,
        } = curValue;
        const curValueFormatted = {
          experiencePoints,
          hoveredChampionApexId,
          hardwareProfiles: [
            {
              hardwareId,
              username,
            },
          ],
        };
        return !deepEqual(
          UpdateApexPlayerModel(
            { ...curValueFormatted },
            { isPostValidation: true, removeEmpty: true },
          ),
          newValue,
        );
      },
      model: ApexPlayerModel,
    });
  }
}

export async function updatePlayerStats(profileId, stats, isOverlay) {
  if (!profileId) return;
  for (const season in stats) {
    const currentSeason = readState.volatile.currentSeasons?.season;
    if (isOverlay && season !== "ALL" && currentSeason !== season) continue;
    for (const mode in stats[season]) {
      const { characters, weapons, ...restPlayerStats } = stats[season][mode];
      await readData(["apex", "playerStats", profileId, season, mode]);
      const playerStats = clone(
        readState.apex.playerStats?.[profileId]?.[season]?.[mode],
      );
      const newPlayerStats = {
        ...(playerStats || {}),
        ...restPlayerStats,
        updatedAt: new Date(),
      };
      newPlayerStats[isPersistent] = MAX_TIME;
      writeState.apex.playerStats[profileId][season][mode] = newPlayerStats;

      const seasonId = readState.apex.meta.seasons?.[season]?.id;
      const platformProfileId = readState.apex.profiles[profileId]?.id;

      if (weapons) {
        await readData(["apex", "playerWeaponStats", profileId, season, mode]);
        const weaponsMeta = readState.apex.meta.weapons;
        const playerWeaponStats = clone(
          readState.apex.playerWeaponStats?.[profileId]?.[season]?.[mode],
        );

        const newPlayerWeaponStats = {
          [isPersistent]: MAX_TIME,
        };

        for (const weapon in weapons) {
          newPlayerWeaponStats[weapon] = {
            ...playerWeaponStats?.[weapon],
            ...weapons[weapon],
            updatedAt: new Date(),
          };
        }

        writeState.apex.playerWeaponStats[profileId][season][mode] =
          newPlayerWeaponStats;

        if (!isOverlay)
          for (const weapon in weapons) {
            if (
              seasonId &&
              platformProfileId &&
              weaponsMeta?.[weapon]?.id &&
              (season === "ALL" ||
                currentSeason === season ||
                !playerWeaponStats?.[weapon]?.id)
            ) {
              const newWeaponData = UpdateApexPlayerWeaponStatsModel(
                { ...newPlayerWeaponStats[weapon] },
                { isPostValidation: true, removeEmpty: true },
              );

              await upsertLocalData({
                curValue: playerWeaponStats?.[weapon],
                newValue: newWeaponData,
                updateKey: "playerWeaponSeasonStats",
                nestedId: weapon,
                getQuery: API.getPlayerWeaponSeasonStats({
                  gameMode: mode,
                  platformProfileId,
                  seasonId,
                }),
                writeStatePath: [
                  "apex",
                  "playerWeaponStats",
                  profileId,
                  season,
                  mode,
                ],
                createQuery: API.createPlayerWeaponSeasonStats({
                  playerWeaponSeasonStats: {
                    ...newWeaponData,
                    gameMode: mode,
                    platformProfileId,
                    seasonId,
                    weaponId: weaponsMeta[weapon].id,
                  },
                }),
                updateQuery: API.updatePlayerWeaponSeasonStats,
                shouldUpdate: (curValue, newValue) => {
                  return !deepEqual(
                    UpdateApexPlayerWeaponStatsModel(
                      { ...curValue },
                      { isPostValidation: true, removeEmpty: true },
                    ),
                    newValue,
                  );
                },
                model: ApexPlayerWeaponStatsModel,
                mergeFn: mergeNestedObjWithApi,
              });
            }
          }
      }

      if (characters) {
        await readData(["apex", "playerLegendStats", profileId, season, mode]);
        const legendsMeta = readState.apex.meta.legends;
        const playerLegendStats = clone(
          readState.apex.playerLegendStats?.[profileId]?.[season]?.[mode],
        );
        const newPlayerLegendStats = {
          [isPersistent]: MAX_TIME,
        };

        for (const legend in characters) {
          newPlayerLegendStats[legend] = {
            ...playerLegendStats?.[legend],
            ...characters[legend],
            updatedAt: new Date(),
          };
        }

        writeState.apex.playerLegendStats[profileId][season][mode] =
          newPlayerLegendStats;

        if (!isOverlay)
          for (const legend in characters) {
            if (
              seasonId &&
              platformProfileId &&
              legendsMeta?.[legend]?.id &&
              (season === "ALL" ||
                currentSeason === season ||
                !playerLegendStats?.[legend]?.id)
            ) {
              const newLegendData = UpdateApexPlayerLegendStatsModel(
                { ...newPlayerLegendStats[legend] },
                { isPostValidation: true, removeEmpty: true },
              );

              await upsertLocalData({
                curValue: playerLegendStats?.[legend],
                newValue: newLegendData,
                updateKey: "playerChampionSeasonStats",
                nestedId: legend,
                getQuery: API.getPlayerChampionSeasonStats(
                  ApexPlayerChampionSeasonStatsInput({
                    gameMode: GameMode[mode] ?? GameMode.ALL,
                    platformProfileId,
                    seasonId,
                  }),
                ),
                writeStatePath: [
                  "apex",
                  "playerLegendStats",
                  profileId,
                  season,
                  mode,
                ],
                createQuery: API.createPlayerChampionSeasonStats({
                  playerChampionSeasonStats: {
                    ...newLegendData,
                    gameMode: mode,
                    platformProfileId,
                    seasonId,
                    championId: legendsMeta[legend].id,
                  },
                }),
                updateQuery: API.updatePlayerChampionSeasonStats,
                shouldUpdate: (curValue, newValue) => {
                  return !deepEqual(
                    UpdateApexPlayerLegendStatsModel(
                      { ...curValue },
                      { isPostValidation: true, removeEmpty: true },
                    ),
                    newValue,
                  );
                },
                model: ApexPlayerLegendStatsModel,
                mergeFn: mergeNestedObjWithApi,
              });
            }
          }
      }

      if (
        isOverlay ||
        !seasonId ||
        !platformProfileId ||
        (playerStats?.id && currentSeason !== season && season !== "ALL")
      )
        continue;

      const newPlayerSeasonStats = UpdateApexPlayerStatsModel(
        { ...newPlayerStats },
        { isPostValidation: true, removeEmpty: true },
      );

      await upsertLocalData({
        curValue: playerStats,
        newValue: newPlayerSeasonStats,
        updateKey: "playerSeasonStats",
        writeStatePath: ["apex", "playerStats", profileId, season, mode],
        getQuery: API.getPlayerSeasonStats({
          gameMode: mode,
          platformProfileId,
          seasonId,
        }),
        createQuery: API.createPlayerSeasonStats({
          playerSeasonStats: {
            ...newPlayerSeasonStats,
            // @ts-ignore This function will be deprecated soon
            gameMode: mode,
            platformProfileId,
            seasonId,
          },
        }),
        // @ts-ignore This function will be deprecated soon
        updateQuery: API.updatePlayerSeasonStats,
        shouldUpdate: (curValue, newValue) => {
          return !deepEqual(
            UpdateApexPlayerStatsModel(
              { ...curValue },
              { isPostValidation: true, removeEmpty: true },
            ),
            newValue,
          );
        },
        model: ApexPlayerStatsModel,
      });
    }
  }
}

export function addMatchToState(match) {
  const oldMatch = clone(readState.apex.matches?.[match.apexId]);
  const newMatch = {
    ...(oldMatch || {}),
    ...match,
    [isPersistent]: MAX_TIME,
  };
  writeState.apex.matches[match.apexId] = newMatch;
  return newMatch;
}

export async function addMatch(profileId, match, isOverlay) {
  if (!profileId || !match) return;
  const seasons = ["ALL", match.season.apexId];
  const modes = ["ALL", match.gameMode];
  for (const season of seasons) {
    for (const mode of modes) {
      await readData(["apex", "matchlists", profileId, season, mode]);
      let matchlist = clone(
        readState.apex.matchlists[profileId]?.[season]?.[mode] ?? [],
      );

      if (!Array.isArray(matchlist)) matchlist = [];

      const { id, apexId, gameStartedAt } = match;
      const matchlistMatch = {
        id,
        apexId,
        gameStartedAt,
        season: match.season,
      };

      matchlist.unshift(matchlistMatch);
      matchlist.sort((a, b) => b.gameStartedAt - a.gameStartedAt);
      matchlist[isPersistent] = MAX_TIME;
      writeState.apex.matchlists[profileId][season][mode] = matchlist;
    }
  }
  const newMatch = addMatchToState(match);
  if (
    !isOverlay &&
    newMatch?.playerMatchStats &&
    newMatch.id &&
    readState.apex.meta.seasons
  ) {
    const seasonId = readState.apex.meta.seasons?.[newMatch.season.apexId]?.id;
    const [playerStats, playerWeaponStats] = newMatch.playerMatchStats.reduce(
      (acc, player) => {
        const {
          playerMatchWeaponStats,
          damage_done,
          platformId,
          championId,
          team,
          respawnsGiven,
          revivesGiven,
          survivalTime,
          assists,
          deaths,
          headshots,
          hits,
          kills,
          knockdowns,
          shots,
          rankedPoints,
          total_ranked_points,
          userAccountId,
        } = player;
        const profile = readState.apex.profiles[platformId];
        if (team.apexId && profile?.id && championId)
          acc[0].push({
            matchId: newMatch.id,
            championId,
            seasonId,
            damage_done,
            platformProfileId: profile?.id,
            userAccountId,
            respawnsGiven,
            revivesGiven,
            survivalTime,
            assists,
            deaths,
            headshots,
            hits,
            kills,
            knockdowns,
            shots,
            rankedPoints,
            total_ranked_points,
            team: {
              apexId: team.apexId,
              placement: team.placement,
              seasonId,
            },
          });
        if (playerMatchWeaponStats && profile?.id) {
          for (const weapon of playerMatchWeaponStats) {
            const { damage_done, headshots, hits, kills, shots, weaponId } =
              weapon;
            acc[1].push({
              matchId: newMatch.id,
              seasonId,
              platformProfileId: profile?.id,
              damage_done,
              headshots,
              hits,
              kills,
              shots,
              weaponId,
            });
          }
        }
        return acc;
      },
      [[], []],
    );
    return postData(
      API.updateMatch({
        id: newMatch.id,
        playerStats,
        playerWeaponStats,
      }),
      noopModel,
      ["volatile", "updateApexMatch"],
      { skipLoadingPlaceholder: true },
    );
  }
}

export async function updateLiveGame(match, isOverlay) {
  let liveGame = clone(readState.apex.liveGame);

  if (match) {
    liveGame = {
      ...(liveGame || {}),
      ...match,
    };

    liveGame[isVolatile] = true;
  } else {
    liveGame = null;
  }

  writeState.apex.liveGame = liveGame;
  writeState.apex.liveGameTime = new Date();

  if (match && !isOverlay) {
    await readData(["apex", "matches", match.apexId]);

    if (
      !readState.apex.matches?.[match.apexId] &&
      readState.volatile.currentSeasons
    ) {
      const seasonId =
        readState.apex.meta.seasons?.[readState.volatile.currentSeasons?.season]
          ?.id;

      const newMatch = clone(match);
      newMatch[isPersistent] = true;
      writeState.apex.matches[match.apexId] = newMatch;

      const { gameMode, gameStartedAt, map, apexId } = match;

      postData(
        API.createMatch({
          match: {
            apexId,
            seasonId,
            map,
            gameMode,
            gameStartedAt: new Date(gameStartedAt * 1000),
          },
        }),
        ApexCreateMatchModel,
        ["apex", "matches", match.apexId],
        { mergeFn: mergeLocalWithApi, skipLoadingPlaceholder: true },
      ).catch((err) => {
        if (err?.message?.includes("apexId has already been taken")) {
          getData(
            API.getMatchId({
              seasonId,
              apexId,
            }),
            ApexMatchModel,
            ["apex", "matches", apexId],
            {
              skipSafetyCheck: true,
              expiryTime: MAX_TIME,
              mergeFn: mergeLocalWithApi,
            },
          );
        }
      });
    }

    if (match?.playerMatchStats) {
      for (const player of match.playerMatchStats) {
        const { platformId, hardwareId, username, apexId } = player;
        const cachedPlayer = readState.apex.profiles[platformId];

        // eslint-disable-next-line no-await-in-loop
        await upsertLocalData({
          curValue: cachedPlayer,
          writeStatePath: ["apex", "profiles", platformId],
          getQuery: API.getPlayer({ platformId }),
          createQuery: API.createPlayer({
            platformProfile: {
              platformId,
              profile: {
                apexId,
              },
              hardwareProfiles: [
                {
                  hardwareId,
                  username,
                },
              ],
            },
          }),
          model: ApexPlayerModel,
        });
      }
    }
  }
}
